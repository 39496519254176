import { render, staticRenderFns } from "./index.vue?vue&type=template&id=8ce48b36&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiCarouselItems: require('/codebuild/output/src4216478623/src/med-el-hearpeers/components/Ui/Carousel/Items.vue').default,UiCarouselButtons: require('/codebuild/output/src4216478623/src/med-el-hearpeers/components/Ui/Carousel/Buttons.vue').default,UiCarouselDetailsHearingJourney: require('/codebuild/output/src4216478623/src/med-el-hearpeers/components/Ui/Carousel/Details/HearingJourney.vue').default,TransitionExpand: require('/codebuild/output/src4216478623/src/med-el-hearpeers/components/Transition/Expand.vue').default,PageVideoModal: require('/codebuild/output/src4216478623/src/med-el-hearpeers/components/Page/VideoModal.vue').default})
