//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    id: {
      type: String,
      default: 'default',
      required: true,
    },
    carouselType: {
      type: String,
      default: 'mentor',
      required: false,
    },
    items: {
      type: Array,
      default() {},
    },
    link: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: 'default',
    },
    slideBy: {
      type: Number,
      default: 1,
    },
    single: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      scrollPosition: 0,
      scrollWidth: 0,
      offsetWidth: 0,

      itemWidth: 0,
      details: false,
      currentItem: null,
      selectedVideoUrl: '',
    }
  },
  computed: {
    scrollableRight() {
      return !(this.scrollPosition + this.offsetWidth >= this.scrollWidth)
    },
    scrollableLeft() {
      return this.scrollPosition !== 0
    },
  },
  watch: {
    scrollableLeft(scrollable) {
      if (scrollable) {
        this.$refs.carouselInner?.classList.add('scrollable-left')
      } else {
        this.$refs.carouselInner?.classList.remove('scrollable-left')
      }
    },
    scrollableRight(scrollable) {
      if (scrollable) {
        this.$refs.carouselInner?.classList.add('scrollable-right')
      } else {
        this.$refs.carouselInner?.classList.remove('scrollable-right')
      }
    },
  },
  mounted() {
    window.addEventListener('resize', this.resize)
    this.$nextTick(() => {
      this.resize()
      new ResizeObserver(this.resize).observe(this.$refs.carouselItems.$el, {
        box: 'border-box',
      })
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.resize)
  },
  methods: {
    resize() {
      this.scrollPosition = this.$refs.carouselItems.$el.scrollLeft
      this.scrollWidth = this.$refs.carouselItems.$el.scrollWidth
      this.itemWidth = this.$refs.carouselItems.$el?.firstChild?.offsetWidth
      this.offsetWidth = this.$refs.carouselItems.$el?.offsetWidth
    },

    handleScroll() {
      this.scrollPosition = this.$refs.carouselItems.$el.scrollLeft
    },

    prev() {
      if (this.scrollPosition > this.itemWidth) {
        this.scrollPosition -= this.itemWidth * this.slideBy
      } else {
        this.scrollPosition = 0
      }
      this.scroll(this.scrollPosition)
    },
    next() {
      if (this.scrollPosition < this.scrollWidth) {
        this.scrollPosition += this.itemWidth * this.slideBy
      } else {
        this.scrollPosition = this.scrollWidth
      }
      this.scroll(this.scrollPosition)
    },

    scroll(value) {
      this.$refs.carouselItems.$el.scrollTo({
        left: value,
        behavior: 'smooth',
      })
    },

    setDetails(value) {
      this.currentItem = value
      this.details = value !== null
    },
    setSelectedVideoUrl(videoUrl) {
      this.selectedVideoUrl = videoUrl
    },

    setShowModal(showModal) {
      // You can add additional logic here if needed
      this.$emit('set-show-modal', showModal)
    },

    closeVideoModal() {
      this.selectedVideoUrl = ''
      // You can add additional logic here if needed
      this.$emit('close-video-modal')
    },
  },
}
