var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"carousel",class:'carousel carousel--' + _vm.type + ' carousel--size-' + _vm.size,staticStyle:{"direction":"ltr"},attrs:{"id":'carousel-' + _vm.id}},[_c('div',{ref:"carouselInner",class:{
      carousel__inner:
        !['en', 'de', 'en_au'].includes(_vm.$i18n.locale) ||
        _vm.type !== 'hearing-stories',
      carousel_new_wrapper:
        ['en', 'de', 'en_au'].includes(_vm.$i18n.locale) &&
        _vm.type === 'hearing-stories',
    }},[_c('UiCarouselItems',{ref:"carouselItems",attrs:{"item-type":_vm.type,"items":_vm.items,"link":_vm.link,"carousel-type":_vm.carouselType,"current":_vm.currentItem,"setSelectedVideoUrl":_vm.setSelectedVideoUrl,"setShowModal":_vm.setShowModal},on:{"carousel:scroll":_vm.handleScroll,"carousel:resize":_vm.resize,"carousel:details":_vm.setDetails},scopedSlots:_vm._u([{key:"items",fn:function(){return [_vm._t("items")]},proxy:true}],null,true)}),_vm._v(" "),(_vm.scrollableLeft || _vm.scrollableRight)?_c('UiCarouselButtons',{ref:"carouselButtons",attrs:{"id":_vm.id,"prev":_vm.scrollableLeft,"next":_vm.scrollableRight,"single":_vm.single},on:{"carousel:prev":_vm.prev,"carousel:next":_vm.next}}):_vm._e()],1),_vm._v(" "),(
      ['en', 'de', 'en_au'].includes(_vm.$i18n.locale) &&
      _vm.type === 'hearing-stories'
    )?_c('div',[_vm._m(0)]):_vm._e(),_vm._v(" "),(_vm.type === 'hearing-journey')?_c('TransitionExpand',[(_vm.details)?_c('UiCarouselDetailsHearingJourney',{attrs:{"id":_vm.items[_vm.currentItem].id,"index":_vm.currentItem},on:{"hideDetails":function($event){return _vm.setDetails(null)}}}):_vm._e()],1):_vm._e(),_vm._v(" "),_c('PageVideoModal',{attrs:{"showModal":_vm.selectedVideoUrl !== '',"youtubeUrl":_vm.selectedVideoUrl},on:{"close":_vm.closeVideoModal}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"new-hearing-stories-all-link",attrs:{"href":"/hear/hearing-stories"}},[_c('span',[_vm._v("See all stories")]),_vm._v(" "),_c('img',{staticClass:"hearing_diary_button_icon",attrs:{"src":require("assets/img/front-white-arrow.svg"),"alt":""}})])}]

export { render, staticRenderFns }